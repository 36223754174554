<template>
  <phototype-template
    :skin-type="skinType"
    :skin-color="skinColor"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import PhototypeTemplate from '@/modules/questionnaire/components/steps/common/identity/phototype/PhototypeTemplate.vue';

import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';
import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';

const { field, requiredField } = fieldBuilder;

const fieldsToReset = ['prolongedHealingTime', 'wishesLightening'];

const FIELDS = [
  field('skinColor'),
  requiredField('skinType'),
  ...fieldsToReset.map(fieldName => field(fieldName))
];

export default {
  name: 'Phototype',
  components: {
    PhototypeTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    skinType() {
      this.resetStoreFieldsByName(fieldsToReset);

      this.showNextStep();
    }
  }
};
</script>
